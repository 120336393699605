// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-articles-js": () => import("./../../../src/pages/articles.js" /* webpackChunkName: "component---src-pages-articles-js" */),
  "component---src-pages-faq-js": () => import("./../../../src/pages/faq.js" /* webpackChunkName: "component---src-pages-faq-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-news-js": () => import("./../../../src/pages/news.js" /* webpackChunkName: "component---src-pages-news-js" */),
  "component---src-pages-partners-js": () => import("./../../../src/pages/partners.js" /* webpackChunkName: "component---src-pages-partners-js" */),
  "component---src-pages-streamers-js": () => import("./../../../src/pages/streamers.js" /* webpackChunkName: "component---src-pages-streamers-js" */),
  "component---src-pages-support-us-js": () => import("./../../../src/pages/support-us.js" /* webpackChunkName: "component---src-pages-support-us-js" */),
  "component---src-pages-terms-js": () => import("./../../../src/pages/terms.js" /* webpackChunkName: "component---src-pages-terms-js" */),
  "component---src-templates-article-template-js": () => import("./../../../src/templates/articleTemplate.js" /* webpackChunkName: "component---src-templates-article-template-js" */),
  "component---src-templates-game-template-js": () => import("./../../../src/templates/gameTemplate.js" /* webpackChunkName: "component---src-templates-game-template-js" */),
  "component---src-templates-news-template-js": () => import("./../../../src/templates/newsTemplate.js" /* webpackChunkName: "component---src-templates-news-template-js" */),
  "component---src-templates-post-template-js": () => import("./../../../src/templates/postTemplate.js" /* webpackChunkName: "component---src-templates-post-template-js" */),
  "component---src-templates-streamer-template-js": () => import("./../../../src/templates/streamerTemplate.js" /* webpackChunkName: "component---src-templates-streamer-template-js" */)
}

