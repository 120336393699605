import { createStore as reduxCreateStore } from "redux"
import { TOGGLE_CURRENCY, TOGGLE_FGCTITLE } from "../actions/types"

const initialState = { currency: false, fgcTitle: false }

const reducer = (state, action) => {
  switch (action.type) {
    case TOGGLE_CURRENCY:
      return { ...state, currency: !state.currency }
    case TOGGLE_FGCTITLE:
      return { ...state, fgcTitle: !state.fgcTitle }
    default:
      return state
  }
}

const createStore = () => reduxCreateStore(reducer, initialState)
export default createStore
